import React, { useRef, useState } from "react";
import useFadeIn from "@src/hooks/useFadeIn";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller } from "swiper/modules";
import TabBar from "@src/components/TabBar";
import "swiper/css";

import styles from "./index.module.scss";
import { tabs } from "./constant";
import TabItem from "@src/components/TabItem";
import Tag from "@src/components/Tag";
import SectionTitle from "@src/components/SectionTitle";

export default () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  const ref = useRef(null);
  const [visible] = useFadeIn(ref);
  const handleClick = (index) => {
    setCurrentIndex(index);
    controlledSwiper.slideTo(index);
  };
  const onSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
  };
  return (
    <div className={styles.root}>
      <div className={styles.container} name="Use-Cases">
        {/* <Tag>Use cases</Tag> */}
        <SectionTitle
          transition
          visible={visible}
          ref={ref}
          className={styles.title}
        >
          Create new possibilities
        </SectionTitle>
        <div className={cn(styles.tabWrapper, "pc-view")}>
          {tabs.map(({ title }, index) => {
            return (
              <TabBar
                className={styles.myTabBar}
                key={title}
                title={title}
                checked={currentIndex === index}
                onClick={() => handleClick(index)}
              />
            );
          })}
        </div>
        <Swiper
          className={cn(styles.tabWrapper, "mobile-view")}
          modules={[Controller]}
          controller={{ control: controlledSwiper }}
          onSwiper={setSecondSwiper}
        >
          {tabs.map(({ title }, index) => {
            return (
              <SwiperSlide className={styles.swiperItem} key={title}>
                <TabBar
                  className={styles.myTabBar}
                  title={title}
                  checked={currentIndex === index}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Swiper
          modules={[Controller]}
          className={styles.swiper}
          onSlideChange={onSlideChange}
          onSwiper={setControlledSwiper}
          controller={{ control: secondSwiper }}
          autoHeight={true}
          slidesPerView="auto"
          spaceBetween={16}
          centeredSlides={true}
          centeredSlidesBounds={true}
        >
          {tabs.map(({ title, subTitle, cover, content, link, coverS }) => {
            return (
              <SwiperSlide key={title}>
                <TabItem
                  title={title}
                  subTitle={subTitle}
                  cover={cover}
                  coverS={coverS}
                  content={content}
                  link={link}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
