import React, { useEffect, useRef, useState } from 'react';
import useFadeIn from '@src/hooks/useFadeIn';
import cn from 'classnames';
import styles from './index.module.scss';
import Tag from '@src/components/Tag';
import SectionTitle from '@src/components/SectionTitle';
import { news } from "@src/api/config";
import { TYPEMAP } from "@src/config/constants";
import dayjs from "dayjs";
const Button = ({ children, className }) => {
    return <div className={cn(styles.btn, className)}>
        <span>{children}</span>
    </div>
};

const NewsInfo = ({ tag, publishTime, className }) => {
  return (
    <div>
      <Button className={className}>{tag}</Button>
      <span className={styles.date}>{publishTime}</span>
    </div>
  );
};

const MainCard = ({ data }) => {
    if (!data) return null;
    const { title, pictureLink, type, publishTime, link } = data;
    return (
      <a className={styles.mainCard} href={link} target="_blank">
        <img src={pictureLink} className={styles.cover} alt="cover" />
        <NewsInfo tag={type} publishTime={publishTime} />
        <div className={styles.content}>{title}</div>
      </a>
    );
};

const SecondaryCard = ({ data }) => {
  if (!data) return null;
    const { title, pictureLink, type, publishTime, link } = data;
    return (
      <a className={styles.secondaryCard} target="_blank" href={link}>
        <img src={pictureLink} className={styles.cover} alt="cover" />
        <div>
          <NewsInfo
            className={styles.smBtn}
            tag={type}
            publishTime={publishTime}
          />
          <div className={styles.content}>{title}</div>
        </div>
      </a>
    );
};

export default () => {
    const [data, setData] = useState([]);
    const ref = useRef(null);
  const [visible] = useFadeIn(ref);
  const fetchNewsList = async () => {
    try {
      const { rc, msg, result } = await news();
      if (rc === 0) {
        let { newsList, slideNews } = result;
        slideNews.publishTime = dayjs(slideNews.publishTime).format(
          "MMM DD,YYYY"
        );
        slideNews.type = TYPEMAP[slideNews.type];
        newsList.items.filter((i) => i.type !== "USE_CASE").forEach((i) => {
          if (i.publishTime) {
            i.publishTime = dayjs(i.publishTime).format("MMM DD,YYYY");
          }
           i.type = TYPEMAP[i.type];
        });
        setData([...newsList.items]);
      } else {
        alert(msg);
      }
    } catch (e) {}
  };
  useEffect(() => {
      fetchNewsList()
        // axios.get().then()
        // import('./data').then(({ default: res }) => {
        //     if (!res.data) return;
        //     setData(res.data);
        // })
    }, []);
  const [first, second,third,fourth,...others] = data;
  const res = [second,third,fourth]
    return (
      <div className={styles.root}>
        {/* <Tag>Discover primus</Tag> */}
        <div className={styles.titleWrapper}>
          <SectionTitle
            transition
            visible={visible}
            ref={ref}
            className={styles.title}
          >
            Latest news
          </SectionTitle>
          <a href="/blog" className={cn(styles.link, "pc-view")}>
            View All
          </a>
        </div>
        <div className={styles.container}>
          <MainCard data={first} />
          <div className={styles.cardWrapper}>
            {res.map((item, index) => (
              <SecondaryCard key={index} data={item} />
            ))}
            <a href="/blog" className={cn("mobile-view", styles.link)}>
              View All
            </a>
          </div>
        </div>
      </div>
    );
};
