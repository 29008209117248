import React, { useState } from 'react';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import cn from 'classnames';
import styles from './index.module.scss';
import ShareIcon from '../ShareIcon';

export default ({ children, className, active, options }) => {
    const [visible, setVisible] = useState(false);
    const handleVisibleChange = (val) => {
        setVisible(val);
    };
    const iconColor = active ? '#101010' : 'white';
    const menu = () => {
        return <div className={cn(styles.menuContainer, {
            [styles.active]: active,
        })}>
            {
                options.map(({ title, hasIcon, link, localLink }) => {
                    if (link) {
                        return <a href={link} target='_blank' className={styles.menuItem} key={title}>
                            <span>{title}</span>
                            {hasIcon && <ShareIcon color={iconColor} />}
                        </a>
                    }
                    const onClick = (e) => {
                        e.preventDefault();
                        if (localLink) {
                            window.location.hash = '/';
                            window.location.replace(localLink);
                        }
                    }
                    return <a className={styles.menuItem} key={title} onClick={onClick} href={localLink}>
                        <span>{title}</span>
                        {hasIcon && <ShareIcon color={iconColor} />}
                    </a>
                })
            }
        </div>
    }
    return <Dropdown
        trigger={['hover']}
        overlay={menu}
        animation="slide-up"
        onVisibleChange={handleVisibleChange}
    >
        <div className={cn('flex', styles.root, className)}>
            {children}
            <div className={cn(styles.icon, {
                [styles.active]: visible,
            })}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
                    <path fill={iconColor} d="M13.0424 13.3139L17.9924 8.36388C18.0846 8.26837 18.195 8.19219 18.317 8.13978C18.439 8.08737 18.5702 8.05979 18.703 8.05863C18.8358 8.05748 18.9674 8.08278 19.0903 8.13306C19.2132 8.18334 19.3249 8.25759 19.4188 8.35149C19.5127 8.44538 19.5869 8.55703 19.6372 8.67993C19.6875 8.80282 19.7128 8.9345 19.7116 9.06728C19.7105 9.20006 19.6829 9.33128 19.6305 9.45329C19.5781 9.57529 19.5019 9.68564 19.4064 9.77788L13.7494 15.4349C13.5618 15.6224 13.3075 15.7277 13.0424 15.7277C12.7772 15.7277 12.5229 15.6224 12.3354 15.4349L6.67837 9.77788C6.58286 9.68564 6.50668 9.57529 6.45427 9.45329C6.40186 9.33128 6.37428 9.20006 6.37312 9.06728C6.37197 8.9345 6.39727 8.80282 6.44755 8.67993C6.49783 8.55703 6.57209 8.44538 6.66598 8.35149C6.75987 8.25759 6.87152 8.18334 6.99442 8.13306C7.11732 8.08278 7.249 8.05748 7.38177 8.05863C7.51455 8.05979 7.64577 8.08737 7.76778 8.13978C7.88978 8.19219 8.00013 8.26837 8.09237 8.36388L13.0424 13.3139Z" />
                </svg>
            </div>
        </div>
    </Dropdown>
};
