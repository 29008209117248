import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export default ({ children, className: customcls, visible, transition }) => {
    return (
      <div className={customcls}>
        {transition ? (
          visible && (
            <div
              className={cn(styles.sectionContent, "section-motion", customcls)}
            >
              {children}
            </div>
          )
        ) : (
          <div className={cn(styles.sectionContent, customcls)}>{children}</div>
        )}
      </div>
    );
};
