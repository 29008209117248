import { useEffect } from 'react';

let scrollTimeout;
export default () => {
    useEffect(() => {
        const handleHashChange = () => {
            clearTimeout(scrollTimeout);
            if (!window.location.hash) return;
            scrollTimeout = setTimeout(() => {
                const id = window.location.hash.substring(1);
                const targetDom = window.document.querySelector(`[name="${id}"]`);
                if (!targetDom) return;
                targetDom.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                });
            }, 300);
        };
        handleHashChange();
        window.addEventListener('hashchange', handleHashChange);
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        }
    }, []);
};