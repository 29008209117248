import React, { forwardRef } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export default forwardRef(({ children, className: customcls, visible, transition }, ref) => {
    return <div className={cn(styles.root, customcls)} ref={ref}>
        <div className={cn(styles.sectionTitle, styles.block)}>{children}</div>
        {
            transition ? (visible && <div className={cn(styles.sectionTitle, 'section-motion')}>{children}</div>) : <div className={styles.sectionTitle}>{children}</div>
        }
    </div>
});
