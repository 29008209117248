import React from 'react';
import NavBar from '@src/components/NavBar';
import PageHeader from './PageHeader';
import PageFooter from '../Home/PageFooter';
import News from './News';

export default () => {
    const onLogoClick = () => {
        window.location.href = '/';
    }
    return <>
        <NavBar active={true} onLogoClick={onLogoClick} />
        <PageHeader />
        <News />
        <PageFooter />
    </>
};
