import React, { useState } from "react";
import cn from "classnames";
import { GithubLink, NavMenus, DocsLink } from "@src/constant/navLinks";
import styles from "./index.module.scss";
import Dropdown from "../Dropdown";
import Button from "../Button";
import Logo from "../Logo";
import MenuButton from "../MenuButton";
import MobileMenu from "../MobileMenu";
import { handleBackTop } from "@src/lib/utils";

export default ({ active, onLogoClick = handleBackTop }) => {
  const fontColor = active ? "color-black" : "color-white";
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    const newVal = !open;
    setOpen(newVal);
    window.document.body.style.overflow = newVal ? "hidden" : "auto";
  };
  return (
    <>
      <div
        className={cn(styles.root, {
          [styles.whiteTheme]: active,
        })}
      >
        <div className={styles.wrapper}>
          <Logo
            onClick={onLogoClick}
            fontColor={active ? "#101010" : "white"}
            iconColor={active ? "#fff" : "#FD4C00"}
          />
          <div className={cn("flex middle pc-view", styles.menu)}>
            {NavMenus.map(({ title, subMenus }) => {
              return (
                <Dropdown active={active} options={subMenus} key={title}>
                  <div
                    className={cn(
                      "public-sans",
                      fontColor,
                      styles.dropdownLabel
                    )}
                  >
                    {title}
                  </div>
                </Dropdown>
              );
            })}
          </div>
          <div className="pc-view">
            <Button
              link={DocsLink}
              isDark={active}
              className={styles.rightButton}
            >
              Documentation
            </Button>
          </div>
          <div className="flex mobile-view">
            <MenuButton isDark={active} open={open} onClick={handleOpen} />
          </div>
        </div>
      </div>
      <MobileMenu isDark={active} open={open} onClose={handleOpen} />
    </>
  );
};
