import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export const Card = ({ title, icon, content }) => {
    return <div className={styles.card}>
        <img className={styles.icon} src={icon} alt="icon" />
        <div>
            <div className={styles.title}>{title}</div>
            <div className={styles.content}>{content}</div>
        </div>
    </div>
};

export const AnimateCard = ({ title, content, icon, iconBg }) => {

    return <>
        <div className={cn(styles.animateCardRoot, 'pc-view')}>
            <div className={styles.animateCard}>
                <div className={styles.firstFrame}>
                    <img alt="icon" className={styles.icon} src={icon} />
                    <div className={styles.title}>{title}</div>
                </div>
                <div className={styles.secondFrame} style={{ backgroundImage: `url(${iconBg})` }}>
                    {content}
                </div>
            </div>
        </div>
        <div className={cn('flex mobile-view', styles.mobileCard)}>
            <img className={styles.icon} src={icon} alt="icon" />
            <div className={styles.title}>{title}</div>
            <div className={styles.content}>{content}</div>
        </div>
    </>
};
