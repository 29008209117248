import React from 'react';
import cn from 'classnames';
import './index.scss';

export default ({ fontColor, iconColor, className, onClick }) => {
    const color1 = fontColor;
    const color2 = iconColor;
    return <div className={cn('global-logo-wrapper', className, {
        pointer: onClick,
    })} onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" className="global-logo" viewBox="0 0 32 32" fill="none">
            <g clipPath="url(#clip0_640_3622)">
                <path fill={color1} d="M27.5407 0.174805H3.93438C1.76148 0.174805 0 1.93629 0 4.10919V27.7155C0 29.8884 1.76148 31.6499 3.93438 31.6499H27.5407C29.7136 31.6499 31.4751 29.8884 31.4751 27.7155V4.10919C31.4751 1.93629 29.7136 0.174805 27.5407 0.174805Z" />
                <path fill={color2} d="M25.179 4.10913H6.29605V6.46905C6.29605 7.77206 7.35297 8.82898 8.65597 8.82898H22.8191V22.9921H13.3776C12.0746 22.9921 11.0177 24.049 11.0177 25.352V27.7119H25.1807C26.4837 27.7119 27.5407 26.655 27.5407 25.352V6.47082C27.5407 5.16782 26.4837 4.1109 25.1807 4.1109L25.179 4.10913Z" />
                <path fill={color2} d="M12.5686 20.0799C13.1687 19.4797 13.8202 19.416 15.6933 19.416C17.5663 19.416 19.234 17.8315 19.234 15.8752C19.234 13.919 17.6496 12.3345 15.6933 12.3345C13.737 12.3345 12.1525 13.919 12.1525 15.8752C12.1525 18.5096 11.7329 18.8743 11.5258 19.1204C11.2054 19.501 8.42585 22.2203 8.42585 22.2203C8.10541 22.5 7.38664 22.994 6.29608 22.994H5.11523C4.46373 22.994 3.93439 23.5215 3.93439 24.1748V26.5347C3.93439 27.1862 4.46196 27.7156 5.11523 27.7156H7.47516C8.12666 27.7156 8.656 27.188 8.656 26.5347V25.3539C8.656 23.9039 9.61732 23.0364 9.61732 23.0364L12.5686 20.0799Z" />
            </g>
            <defs>
                <clipPath id="clip0_640_3622">
                    <rect width="31.4751" height="31.4751" fill={color1} transform="translate(0 0.174805)" />
                </clipPath>
            </defs>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" className="global-logo-text" viewBox="0 0 112 32" fill="none">
            <path fill={color1} d="M0.721252 6.45814H2.22837C3.2101 6.45814 4.0066 7.25463 4.0066 8.23636V8.37781H4.04364C4.31475 6.85217 6.34389 6.08936 10.1327 6.08936C12.3959 6.08936 14.1051 6.56254 15.262 7.51059C16.4188 8.45864 16.9964 9.66938 16.9964 11.1462V18.4898C16.9964 19.5473 16.8432 20.4701 16.535 21.2582C16.2269 22.0462 15.8278 22.6609 15.3361 23.1038C14.8932 23.5231 14.2836 23.8548 13.509 24.1006C12.7344 24.3465 11.9648 24.5065 11.202 24.5806C10.5133 24.6546 9.67638 24.6917 8.69296 24.6917C7.6102 24.6917 6.62005 24.562 5.72252 24.3044C4.82498 24.0467 4.26424 23.6712 4.04364 23.1795V30.2217C4.04364 31.2034 3.24714 31.9999 2.26541 31.9999H0.721252V6.45814ZM11.6988 22.1608C12.3757 21.9755 12.8607 21.6387 13.1571 21.1453C13.4517 20.6536 13.5999 19.9026 13.5999 18.8939V12.3249C13.5999 11.2186 13.4517 10.3935 13.1571 9.85293C12.8624 9.31239 12.3757 8.94866 11.6988 8.76512C11.0218 8.57988 10.0317 8.48895 8.72833 8.48895C7.42497 8.48895 6.45334 8.58157 5.81345 8.76512C5.17355 8.95035 4.7189 9.30734 4.44778 9.83441C4.17667 10.3632 4.04196 11.1933 4.04196 12.3249V18.8939C4.04196 19.9026 4.1834 20.6536 4.4663 21.1453C4.7492 21.6371 5.22239 21.9755 5.88754 22.1608C6.55101 22.3443 7.51084 22.4369 8.76537 22.4369C10.0199 22.4369 11.0218 22.3443 11.6988 22.1608Z" />
            <path fill={color1} d="M22.0499 6.45792H23.4089C24.3906 6.45792 25.1871 7.25441 25.1871 8.23614V9.37448C25.1871 8.80868 25.4329 8.27319 25.9247 7.76969C26.4164 7.2662 27.0445 6.86037 27.8073 6.55222C28.5701 6.24574 29.3194 6.09082 30.0587 6.09082H31.682V8.04418C31.682 8.5342 31.2846 8.93329 30.7929 8.93329H29.8364C28.2619 8.93329 27.1236 9.13704 26.4231 9.54287C25.7226 9.9487 25.3706 10.6812 25.3706 11.7387V22.5445C25.3706 23.5262 24.5741 24.3227 23.5924 24.3227H22.0482V6.45792H22.0499Z" />
            <path fill={color1} d="M37.6226 0H39.1668C39.6568 0 40.0559 0.397407 40.0559 0.889113V2.57978C40.0559 3.0698 39.6585 3.46889 39.1668 3.46889H37.6226C37.1326 3.46889 36.7335 3.07148 36.7335 2.57978V0.889113C36.7335 0.399091 37.1309 0 37.6226 0ZM36.7335 6.45786H38.2777C39.2594 6.45786 40.0559 7.25436 40.0559 8.23609V22.5427C40.0559 23.5245 39.2594 24.321 38.2777 24.321H36.7335V6.45786Z" />
            <path fill={color1} d="M45.1136 6.45787H46.6577C47.6395 6.45787 48.436 7.25436 48.436 8.23609V8.63518C48.7795 6.93779 50.785 6.08909 54.4509 6.08909C57.4281 6.08909 59.2855 6.90074 60.0231 8.52404C60.2453 7.73765 60.9139 7.1348 62.0337 6.71551C63.1535 6.29789 64.5242 6.0874 66.1475 6.0874C68.3619 6.0874 69.9245 6.49996 70.8339 7.3234C71.7432 8.14853 72.1995 9.44515 72.1995 11.2166V22.5394C72.1995 23.5211 71.403 24.3176 70.4213 24.3176H68.8771V11.4389C68.8771 10.9725 68.8401 10.5902 68.766 10.2955C68.6919 10.0008 68.5454 9.70448 68.3231 9.40979C67.8314 8.79516 66.7369 8.487 65.0395 8.487C63.7344 8.487 62.7628 8.57288 62.1246 8.74464C61.4847 8.9164 61.0486 9.21277 60.8145 9.63038C60.5804 10.0497 60.4643 10.6508 60.4643 11.4389V22.5411C60.4643 23.5228 59.6678 24.3193 58.686 24.3193H57.1419V11.4406C57.1419 10.9742 57.1048 10.5919 57.0307 10.2972C56.9566 10.0025 56.8101 9.70616 56.5879 9.41147C56.0961 8.79684 55.0134 8.48868 53.3396 8.48868C51.9857 8.48868 50.9652 8.57456 50.2765 8.74632C49.5878 8.91808 49.1078 9.20772 48.8367 9.61354C48.5656 10.0194 48.4309 10.629 48.4309 11.4406V22.5427C48.4309 23.5245 47.6344 24.321 46.6527 24.321H45.1085V6.45787H45.1136Z" />
            <path fill={color1} d="M78.6345 23.4524C77.7117 22.629 77.2503 21.3307 77.2503 19.5592V6.45825H78.7944C79.7762 6.45825 80.5727 7.25475 80.5727 8.23648V19.3757C80.5727 19.8438 80.6148 20.2193 80.7023 20.5005C80.7882 20.7834 80.9414 21.0731 81.1637 21.3677C81.6066 22.0076 82.6894 22.3276 84.412 22.3276C86.2323 22.3276 87.5121 22.1558 88.2497 21.8106C88.9637 21.4671 89.319 20.839 89.319 19.928V6.45825H90.8631C91.8449 6.45825 92.6414 7.25475 92.6414 8.23648V22.5431C92.6414 23.5249 91.8449 24.3213 90.8631 24.3213H89.319V22.144C88.999 23.8414 87.044 24.6901 83.4522 24.6901C81.1637 24.6901 79.5589 24.2776 78.6361 23.4541L78.6345 23.4524Z" />
            <path fill={color1} d="M98.0621 24.2455V22.7383C98.0621 22.1944 98.5488 21.7785 99.086 21.861C101.435 22.2214 103.161 22.3999 104.261 22.3999C105.835 22.3999 106.967 22.2466 107.655 21.9385C108.344 21.632 108.689 21.0595 108.689 20.2226V18.414C108.689 17.6512 108.432 17.1107 107.915 16.7907C107.398 16.4708 106.561 16.3108 105.406 16.3108H103.377C101.531 16.3108 100.123 15.9302 99.1516 15.1674C98.18 14.4046 97.6934 13.3353 97.6934 11.9562V10.2958C97.6934 7.49038 100.351 6.08936 105.663 6.08936C106.057 6.08936 107.864 6.18871 111.087 6.38404V7.75308C111.087 8.29193 110.611 8.70786 110.077 8.63377C108.321 8.3896 106.8 8.26667 105.515 8.26667C103.767 8.26667 102.575 8.43843 101.935 8.78364C101.295 9.12884 100.975 9.70643 100.975 10.5181V12.0303C100.975 13.2359 102.07 13.8388 104.259 13.8388H106.325C110.089 13.8388 111.971 15.2415 111.971 18.0452V19.5944C111.971 21.44 111.331 22.7501 110.052 23.5247C108.772 24.2993 106.817 24.6866 104.185 24.6866C102.29 24.6866 100.273 24.5435 98.0841 24.2488L98.0621 24.2455Z" />
        </svg>
    </div>
};