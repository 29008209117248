export const GithubLink = "https://github.com/primus-labs/zkFHE-Network-Docs";
// export const DocsLink = 'https://docs.padolabs.org/';
// export const DocsLink = "https://docs-test.padolabs.org/";
export const DocsLink = "https://docs.primuslabs.xyz/";
export const Developer = [
  {
    title: "Github",
    footerTitle: "Github",
    hasIcon: true,
    link: "https://github.com/primus-labs",
  },
  {
    title: "Docs",
    footerTitle: "Docs",
    hasIcon: true,
    link: DocsLink,
  },
  {
    title: "Research",
    footerTitle: "Research",
    hasIcon: true,
    link: "https://github.com/primus-labs/research-and-exploration",
  },
];

export const Discover = [
  {
    title: "Blog",
    footerTitle: "Blog",
    hasIcon: false,
    localLink: "/blog",
  },
  {
    title: "Use Cases",
    footerTitle: "Use Cases",
    hasIcon: false,
    localLink: "/#Use-Cases",
  },
  {
    title: "Data Verification",
    footerTitle: "Data Verification",
    hasIcon: false,
    localLink: "/dataVerification",
  },
];

export const DiscordLink = 'https://discord.com/invite/pdrNxRrApX';

export const JoinUsLinks = {
  x: "https://x.com/primus_labs",
  discord: DiscordLink,
  medium: "https://medium.com/@primuslabs",
};


export const Community = [
    {
        title: 'Join Discord',
        footerTitle: 'Join Discord',
        hasIcon: true,
        link: JoinUsLinks.discord,
    },
    {
        title: 'Follow X',
        footerTitle: 'Follow X',
        hasIcon: true,
        link: JoinUsLinks.x,
    }
];
export const PolicyLink = `${DocsLink}privacy-policy`;

export const Resources = [
  {
    title: "Brand Assets",
    footerTitle: "Brand Assets",
    hasIcon: true,
    link: "https://drive.google.com/drive/folders/14a45d1OSgiLoWAesOnYbqHFdlodBpbzJ?usp=drive_link",
  },
  {
    title: "Privacy Policy",
    footerTitle: "Privacy Policy",
    hasIcon: true,
    link: PolicyLink,
  },
];

export const ZKAttestationLink = 'https://chromewebstore.google.com/detail/pado/oeiomhmbaapihbilkfkhmlajkeegnjhe?pli=1';


export const NavMenus = [{
    title: 'Developer',
    subMenus: Developer,
}, {
    title: 'Discover',
    subMenus: Discover,
}, {
    title: 'Community',
    subMenus: Community,
}];