import React from 'react';
import styles from './index.module.scss';
import PageHeaad from './PageHeader';
import HotEvents from './HotEvents';
import FAQ from './FAQ';
// import Footer from '@src/components/Footer';
import PageFooter from "../Home/PageFooter";
import NavBar from "@src/components/NavBar";

export default () => {
    const onLogoClick = () => {
      window.location.href = "/";
    };
    return (
      <div className={styles.root}>
        <NavBar active={true} onLogoClick={onLogoClick} />
        <PageHeaad />
        <HotEvents />
        <FAQ />
        <PageFooter />
      </div>
    );
};
