import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export default ({ color, className }) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" className={cn(styles.root, className)}>
        <g clipPath="url(#clip0_678_6913)">
            <path fill={color} fillRule="evenodd" clipRule="evenodd" d="M8.75 1.75403C8.97781 1.52623 9.34715 1.52623 9.57496 1.75403L11.4928 3.67185C11.8833 4.06237 11.8833 4.69553 11.4928 5.08606L9.57496 7.00394C9.34716 7.23175 8.97781 7.23176 8.75 7.00395C8.52219 6.77615 8.52219 6.40681 8.74999 6.179L9.89052 5.03843C8.25805 5.20804 6.80377 5.65132 5.67134 6.27066C4.48498 6.91951 3.70871 7.72588 3.38328 8.56708C3.26704 8.86754 2.97542 9.09201 2.65662 9.04562C2.33781 8.99923 2.11287 8.70099 2.20828 8.39328C2.60959 7.09909 3.69887 6.0197 5.11153 5.24708C6.45736 4.51102 8.15649 4.01605 10.0248 3.85374L8.75 2.57899C8.52219 2.35118 8.52219 1.98184 8.75 1.75403ZM2.91667 11.379V10.6123C2.91667 10.3914 2.73758 10.2123 2.51667 10.2123H2.15C1.92909 10.2123 1.75 10.3914 1.75 10.6123V11.5456C1.75 12.0979 2.19772 12.5456 2.75 12.5456H11.25C11.8023 12.5456 12.25 12.0979 12.25 11.5456V10.6123C12.25 10.3914 12.0709 10.2123 11.85 10.2123H11.4833C11.2624 10.2123 11.0833 10.3914 11.0833 10.6123V11.379H2.91667Z" />
        </g>
        <defs>
            <clipPath id="clip0_678_6913">
                <rect width="14" height="14" fill="white" />
            </clipPath>
        </defs>
    </svg>
}