export const handleBackTop = () => {
    if (window.location.hash) {
        window.location.hash = '/';
    }
    window.document.body.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
    });
}
export const isDesktop = () => {
  return window.matchMedia("(min-width: 1024px)").matches;
}
