import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import NavBar from '@src/components/NavBar';
import OurMission from './OurMission';
import HowItWorks from './HowItWorks';
import WhyNeedPrimus from './WhyNeedPrimus';
import BackedBy from './BackedBy';
import UseCases from './UseCases';
import BuildTogether from './BuildTogether';
import Discover from './Discover';
import PageFooter from './PageFooter';
import PageHeader from './PageHeader';
import useAnchor from '@src/hooks/useAnchor';
import Enabling from "./Enabling";

export default () => {
    const [active, setNavStatus] = useState(false);
    const headerRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((enties) => {
            const [entry] = enties;
            setNavStatus(!entry.isIntersecting);
        });
        observer.observe(headerRef.current);
        return () => {
            observer.disconnect();
        }
    }, []);
    useAnchor();
    return (
      <div className={styles.root}>
        <NavBar active={active} />
        <PageHeader ref={headerRef} />
        <BackedBy />
        <OurMission />
        <Enabling />
        <UseCases />
        {/* <HowItWorks /> */}
        <BuildTogether />
        <WhyNeedPrimus />

        <Discover />
        <PageFooter />
      </div>
    );
};