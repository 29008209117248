import React from "react";
import styles from "./index.module.scss";
import Button from "../Button";
import useBreakPoint from "../../hooks/useBreakPoint";
export default ({ title, subTitle, content, cover, link, coverS }) => {
  const bp = useBreakPoint();
  return (
    <div className={styles.tabItem}>
      <img
        src={["l", "xl"].includes(bp) ? cover : coverS}
        alt={title}
        className={styles.cover}
      />
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subTitle}>{subTitle}</div>
        <div className={styles.content}>{content}</div>
        <Button link={link} isDark className={styles.btn}>
          Learn More
        </Button>
      </div>
    </div>
  );
};
