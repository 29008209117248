import React from "react";
import styles from "./index.module.scss";
import SectionContent from "@src/components/SectionContent";
import Button from "@src/components/Button";
import { ZKAttestationLink } from "@src/constant/navLinks";
import { isDesktop } from "@src/lib/utils";
import { DocsLink } from "@src/constant/navLinks";


export default () => {
  const onLaunch = () => {
    if (!isDesktop()) {
      alert("Please use the PADO Extension from the Chrome website platform.");
      return 
    }
    var specialEl = document.querySelector("#pado-extension-inject-el");
    if (specialEl) {
      window.postMessage(
        {
          target: "padoExtension",
          name: "event",
          params: {
            eventName: "",
            methodName: "createTab",
            path: "home.html#/events",
          },
        },
        "*"
      );
    } else {
      window.open(ZKAttestationLink);
    }
  };
  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <div className={styles.title}>
          Bringing all internet data into smart contracts
        </div>
        <SectionContent className={styles.content}>
          Use zkTLS to create a cryptographic attestation solution,{" "}
          <a href={`${DocsLink}data-verification/tech-intro`} target="_blank">
            Data Verification
          </a>
          , to attest any web data from any internet data source, and enable
          sharing the data value with zero-knowledge proofs.
        </SectionContent>
        <Button isDark className={styles.btn} onClick={onLaunch}>
          Launch
        </Button>
      </div>

      <div className={styles.cover} />
    </div>
  );
};
