import React, { useRef, useState, useEffect } from 'react';
import Tag from '@src/components/Tag';
import SectionTitle from '@src/components/SectionTitle';
import SectionContent from '@src/components/SectionContent';
import useFadeIn from '@src/hooks/useFadeIn';
import styles from './index.module.scss';
import videoPCSrc2 from './The-Primus-Network-0815.mp4';
import videoMobileSrc2 from './The-Primus-Network-mobile-0815.mp4';

const title = "The Primus network";
const content =
  "An open network for verifiable confidential computation where workers, data providers, and callers collaborate to unlock data value.";

let _videoSrc;
const PCVideo = videoPCSrc2;
const MobileVideo = videoMobileSrc2;
export default () => {
    const ref = useRef(null);
    const videoRef = useRef(null);
    const [videoSrc, setVideoSrc] = useState(PCVideo);
    useEffect(() => {
        const autoResponse = () => {
            if (document.documentElement.clientWidth < 993) {
                if (_videoSrc === MobileVideo) return;
                _videoSrc = MobileVideo;
                setVideoSrc(MobileVideo);
            } else {
                if (_videoSrc === PCVideo) return;
                _videoSrc = PCVideo;
                setVideoSrc(PCVideo);
            }
        }
        autoResponse();
        window.addEventListener('resize', autoResponse)
        return () => {
            window.removeEventListener('resize', autoResponse);
        }
    }, []);
    useEffect(() => {
        const needPlay = !videoRef.current.paused;
        videoRef.current.load();
        if (needPlay) {
            videoRef.current.play();
        }
    }, [videoSrc]);
    const [visible] = useFadeIn(ref, videoRef);
    return (
        <div className={styles.root}>
            <div className={styles.container}>
                {/* <Tag>How it works</Tag> */}
                <SectionTitle transition ref={ref} visible={visible} className={styles.title}>{title}</SectionTitle>
                <SectionContent transition visible={visible} className={styles.content}>{content}</SectionContent>
                <div className={styles.videoWrapper}>
                    <video ref={videoRef} className={styles.video} autoPlay={false} loop="loop" muted={true} playsInline={true} webkit-playsinline="true">
                        <source src={videoSrc} type='video/mp4'></source>
                    </video>
                </div>
            </div>
        </div>
    );
};