import React, { useRef } from "react";
import styles from "./index.module.scss";
import SectionTitle from "@src/components/SectionTitle";
import bg1 from "./assets/bg1.png";
import bg2 from "./assets/bg2.png";
import bg3 from "./assets/bg3.png";
import bg4 from "./assets/bg4.png";
import useFadeIn from "@src/hooks/useFadeIn";
import { ZKAttestationLink } from "@src/constant/navLinks";
import { isDesktop } from "@src/lib/utils";
const data = [
  {
    id: "LINEA_DEFI_VOYAGE",
    title: (
      <span>
        The Linea Voyage: <br />
        Proof of Humanity
      </span>
    ),
    content:
      "Proof of Humanity is one of the Linea ecosystem activation. Execute PoH event to claim your Linea Voyage XP.",
    bgUrl: bg1,
  },
  {
    id: "BAS_EVENT_PROOF_OF_HUMANITY",
    title: "BAS Attestation Alliance",
    content:
      "The BNB Attestation Service (BAS) is an infrastructure built on the BNB ecosystem for generating attestation to verify information.",
    bgUrl: bg2,
  },
  {
    id: "SIGNX_X_PROGRAM",
    title: "SignX Program",
    content:
      "Sign Protocol is an omni-chain attestation protocol, enabling users to freely attest and verify any information on-chain.",
    bgUrl: bg3,
  },
  // {
  //     id: '',
  //     title: 'Taiko Attestation Campaign',
  //     content: 'Use zkAttestation to start an amazing journey through the Taiko ecosystem and earn your rewrads. ',
  //     bgUrl: bg4,
  // }
];

const Card = ({ id, title, content, bgUrl }) => {
  const onJoin = () => {
    if (!isDesktop()) {
      alert("Please use the PADO Extension from the Chrome website platform.");
      return;
    }
    var specialEl = document.querySelector("#pado-extension-inject-el");
    if (specialEl) {
      window.postMessage(
        {
          target: "padoExtension",
          name: "event",
          params: {
            eventName: id,
            methodName: "createTab",
            path: "home.html#/events",
          },
        },
        "*"
      );
    } else {
      window.open(ZKAttestationLink);
    }
  };
  return (
    <div className={styles.card}>
      <div className={styles.wrapper}>
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.content}>{content}</div>
        </div>
        <a className={styles.btn} target="_blank" href="">
          <span
            onClick={() => {
              onJoin(id);
            }}
          >
            Join
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
          >
            <path
              d="M8.16667 3.41797L12.25 7.5013M12.25 7.5013L8.16667 11.5846M12.25 7.5013L1.75 7.5013"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
      <img alt="bg" className={styles.bg} src={bgUrl} />
    </div>
  );
};

export default () => {
  const ref = useRef();
  const [visible] = useFadeIn(ref);
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <SectionTitle transition ref={ref} visible={visible}>
          Hot events
        </SectionTitle>
        <div className={styles.cardWrapper}>
          {data.map((item) => {
            return <Card key={item.title} {...item} />;
          })}
        </div>
      </div>
    </div>
  );
};
