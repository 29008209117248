import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export default ({ children, isActive, onClick, disabled }) => {
    return <div className={cn(styles.root, 'body-1-regular', {
        [styles.active]: isActive,
        [styles.disabled]: disabled,
    })} onClick={onClick}>{children}</div>
};
