import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export default ({ open, onClick, isDark }) => {
    return <div className={cn(styles.root, {
        [styles.closed]: open,
        [styles.dark]: isDark,
    })} onClick={onClick}>
        <div className={cn(styles.line, styles.line1)} />
        <div className={cn(styles.line, styles.line2, styles.line2_1)} />
        <div className={cn(styles.line, styles.line2, styles.line2_2)} />
        <div className={cn(styles.line, styles.line3)} />
    </div>
};
