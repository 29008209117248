import React from "react";
import cn from "classnames";
import styles from "./index.module.scss";
import leftIcon from "./assets/tab-icon.png";

export default ({ title, checked, onClick, className: customcls }) => {
  return (
    <div
      onClick={onClick}
      className={cn(styles.tabBar, customcls, {
        [styles.checked]: checked,
      })}
    >
      <img
        alt="icon"
        src={leftIcon}
        className={cn(styles.icon, styles.left)}
      />
      <div className={cn(styles.title, customcls)}>{title}</div>
      <img
        alt="icon"
        src={leftIcon}
        className={cn(styles.icon, styles.right)}
      />
    </div>
  );
};
