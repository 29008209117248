import React from 'react';
import styles from './index.module.scss';

const title = "Liberate data and computation with cryptography";

export default () => {
    return <div className={styles.root}>
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.title}>{title}</div>
            </div>
            <div className={styles.cover} />
        </div>
    </div>
};
