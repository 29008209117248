import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { GithubLink, NavMenus } from '@src/constant/navLinks';
import styles from './index.module.scss';
import ShareIcon from '../ShareIcon';
import Button from '../Button';

const MenuItem = ({ title, options, isDark, onClick }) => {
    const fontColor = isDark ? '#101010' : '#fff';
    return <div className={cn(styles.menuItem, {
        [styles.dark]: isDark,
    })}>
        <div className={styles.title}>{title}</div>
        <div className={styles.options}>
            {options.map(({ title, hasIcon, link, localLink }) => {
                if (link) {
                    return <a href={link} target='_blank' className={styles.item}>
                        <div>{title}</div>
                        {hasIcon && <ShareIcon className={styles.icon} color={fontColor} />}
                    </a>
                }
                return <div className={styles.item} onClick={() => onClick(localLink)}>
                    <div>{title}</div>
                    {hasIcon && <ShareIcon className={styles.icon} color={fontColor} />}
                </div>
            })}
        </div>
    </div>
};

export default ({ open, isDark, onClose }) => {
    const [visible, setVisible] = useState(open);
    useEffect(() => {
        setTimeout(() => {
            setVisible(open)
        }, open ? 200 : 0);
    }, [open]);
    const handleClick = (localLink) => {
        onClose?.();
        if (localLink) {
            window.location.hash = '/';
            window.location.replace(localLink);
        }
    };
    return <div className={cn(styles.root, {
        [styles.open]: open,
        [styles.whiteTheme]: isDark,
    })}>
        {
            visible && <>
                <div className={cn(styles.menu, 'fade-in')}>
                    {
                        NavMenus.map(({ title, subMenus }) => {
                            return <MenuItem key={title} title={title} options={subMenus} isDark={isDark} onClick={handleClick} />
                        })
                    }
                </div>
                <div className={cn(styles.btnWrapper, 'fade-in')}>
                    <Button link={GithubLink} isDark={isDark} className={styles.btn}>Start Building</Button>
                </div>
            </>
        }
    </div>
};
