import React, { useRef, useState, useEffect } from "react";
import SectionTitle from "@src/components/SectionTitle";
import SectionContent from "@src/components/SectionContent";
import useFadeIn from "@src/hooks/useFadeIn";
import styles from "./index.module.scss";
import illustration from "./illustration.svg";

const title = "Enabling AI-powered intelligence in Web3";
const content =
  "AI models in Web3 need secure, verifiable data access. Primus bridges Web2 data to Web3, feeding AI models with verified inputs while safeguarding user privacy. It empowers DeFi, decentralized healthcare, and Web3 social applications to leverage the predictive power of AI while ensuring data integrity.";

export default () => {
  const ref = useRef(null);

  const [visible] = useFadeIn(ref);
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.left}>
          <SectionTitle
            transition
            ref={ref}
            visible={visible}
            className={styles.title}
          >
            {title}
          </SectionTitle>
          <SectionContent
            transition
            visible={visible}
            className={styles.content}
          >
            {content}
          </SectionContent>
        </div>
        <img
          className={styles.illustration}
          src={illustration}
        />
      </div>
    </div>
  );
};
