import React, { useRef } from 'react';
import styles from './index.module.scss';
import Tag from '@src/components/Tag';
import SectionTitle from '@src/components/SectionTitle';
import Button from '@src/components/Button';
import useFadeIn from '@src/hooks/useFadeIn';
import { Card, AnimateCard } from '@src/components/Card';
import confidentiality from './assets/confidentiality.png';
import blockchainNeutral from './assets/blockchain-neutral.png';
import communityDriven from './assets/community-driven.png';
import communityDrivenBG from './assets/community-driven-bg.png';
import performance from './assets/performance.png';
import performanceBG from './assets/performance-bg.png';
import verifiability from './assets/verifiability.png'
import verifiabilityBG from './assets/verifiability-bg.png'
import { DocsLink } from "@src/constant/navLinks";

const learnMoreLink = `${DocsLink}introduction/why-need-primus`;
const title = "A new standard for infrastructure";

export default () => {
    const ref = useRef(null);
    const [visible] = useFadeIn(ref);
    return (
      <div className={styles.root}>
        <div className={styles.contentWrapper}>
          <div className={styles.left}>
            {/* <Tag>Tech Advantages</Tag> */}
            <SectionTitle
              transition
              ref={ref}
              visible={visible}
              className={styles.title}
            >
              {title}
            </SectionTitle>
          </div>
          <Button link={learnMoreLink} className={styles.button} isDark>
            Learn More
          </Button>
        </div>
        <div className={styles.cardWrapper}>
          <Card
            icon={confidentiality}
            title="Confidential"
            content="Ensure privacy protection throughout the entire data lifecycle. Implement data minimization principles by leveraging confidential computation."
          />
          <Card
            icon={blockchainNeutral}
            title="Blockchain Neutral"
            content="Provide standardized solutions to enable confidential computation capabilities for all blockchain ecosystems."
          />
        </div>
        <div className={styles.innerCardWrapper}>
          <AnimateCard
            title="Community Driven"
            content="Open-source, no IP or patent issues. Welcome all insights and collaboration."
            icon={communityDriven}
            iconBg={communityDrivenBG}
          />
          <AnimateCard
            title="High Performance"
            content="Use advanced cryptography with highly optimized implementation to achieve the practical confidential computation on encrypted private data."
            icon={performance}
            iconBg={performanceBG}
          />
          <AnimateCard
            title="Verifiable"
            content="Mathematically prove the authenticity of any web data based on standard HTTPS/TLS channel."
            icon={verifiability}
            iconBg={verifiabilityBG}
          />
        </div>
      </div>
    );
};
