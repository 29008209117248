import React, { useMemo } from "react";
import cn from "classnames";
import styles from "./index.module.scss";

export default ({
  children,
  className,
  isDark,
  type = "primary",
  link,
  onClick,
}) => {
  const handleClick = () => {
    if (link) return;
    onClick?.();
  };
  const classNames = useMemo(() => {
    switch (type) {
      case "primary":
        return cn(styles.button, className, {
          [styles.dark]: isDark,
          [styles.clickable]: !!link,
        });
      case "secondary":
        return cn(styles.buttonSecondary, className, {
          [styles.dark]: isDark,
          [styles.clickable]: !!link,
        });
      default:
        return cn(styles.button, className, {
          [styles.dark]: isDark,
          [styles.clickable]: !!link,
        });
    }
  }, [type, isDark, className,link]);
  return (
    <>
      {link ? (
        <a
          target="_blank"
          href={link}
          onClick={handleClick}
          className={classNames}
        >
          {children}
        </a>
      ) : (
        <span className={classNames} onClick={handleClick}>
          {children}
        </span>
      )}
    </>
  );
};
