import { useState, useEffect } from "react";

export default (target, videoRef) => {
    const [visible, setVisible] = useState(false);
    useEffect(
        () => {
            const isMobile = window.document.documentElement.clientWidth < 993;
            const observer = new window.IntersectionObserver((entries) => {
                const [entry] = entries;
                if (!entry.isIntersecting) return;
                if (videoRef?.current) {
                    setTimeout(() => {
                        videoRef.current.play();
                    }, 1000);
                }
                setVisible(true);
                observer.disconnect();
            }, {
                rootMargin: isMobile ? '100px 0px -100px 0px' : '200px 0px -200px 0px',
            });
            observer.observe(target.current);
            return () => {
                observer.disconnect();
            };
        },
        // eslint-disable-next-line
        [],
    );
    return [visible];
};
